.button {
  width: 10px;
  height: 10px;
  font-size: 0;
  background-color: red;
  border: 0;
  border-radius: 10px;
  margin-right: 6px;
  outline: none;
  text-transform: uppercase;
}
.Rec {
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3);
  }
  65% {
    box-shadow: 0px 0px 5px 4px rgba(173, 0, 0, 0.3);
  }
  90% {
    box-shadow: 0px 0px 5px 4px rgba(173, 0, 0, 0);
  }
}
