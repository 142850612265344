.rbc-calendar {
  font-family: var(--chakra-fonts-body);
}

.rbc-time-view {
  border: none;
  margin-top: 20px;
}

.rbc-label {
  font-size: 12px;
}

.rbc-event {
  border-radius: 7px;
  font-size: 13px;
  background: #DA0074;
}

.rbc-time-header {
  display: none;
}

.rbc-day-slot .rbc-event {
  border: 1px solid white;
  margin: auto 5px;
}

.rbc-day-slot .rbc-time-slot {
  border-top: none;
}
