.faq__answer {
  ul {
    li {
      margin-left: 1em;
    }
  }

  p {
    margin-bottom: 1em;
  }
}