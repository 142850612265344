@use "../../../styles/abstracts/spacing";
@use "../../../styles/abstracts/color";
@use "../../../styles/abstracts/size";

$darken-gradient: linear-gradient(0deg, rgb(0 0 0 / 50%), rgb(0 0 0 / 50%));
$lighten-gradient: linear-gradient(
                0deg,
                rgb(255 255 255 / 75%),
                rgb(255 255 255 / 75%)
);

@mixin pseudo-layer {
  content: '';
  display: block;
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.button {
  position: relative;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  span {
    position: relative;
    z-index: 2;
  }

  svg {
    fill: currentcolor;
  }

  &.primary,
  &.secondary,
  &.vibrant {
    border-radius: size.$sp40;

    font-size: 16px;
  }

  &.primary {
    transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out,
    background 0.3s ease-in-out;
    color: color.$conscoius-white;
    padding: spacing.$spacing-xs spacing.$spacing-s;

    &::before,
    &::after {
      @include pseudo-layer;

      border-radius: inherit;
    }

    &::before {
      z-index: 0;
    }

    &::after {
      z-index: 1;
    }

    &:not(:disabled) {
      &:focus-visible,
      &:hover {
        &::before {
          opacity: 1;
        }
      }

      &:active {
        &::after {
          opacity: 1;
        }
      }
    }

    &.primaryOnBlack {
      background: color.$vibrant-gradient;

      &::before {
        background: color.$conscoius-white;
      }

      &::after {
        background: $lighten-gradient;
      }

      &:not(:disabled) {
        &:focus-visible,
        &:hover {
          background: transparent;
          color: color.$vibrant100-digital;
        }

        &:active {
          color: color.$valid-black;
          background: color.$vibrant-gradient;

          &::before {
            opacity: 0;
          }
        }
      }
    }

    &.primaryOnWhite {
      background: color.$valid-black;

      &::before {
        background: color.$vibrant-gradient;
      }

      &::after {
        background: $darken-gradient;
      }

      &:not(:disabled) {
        &:focus-visible,
        &:hover {
          color: color.$conscoius-white;
        }

        &:active {
          color: color.$conscoius-white;
        }
      }
    }
  }

  &.secondary {
    --outer-spacing: #{size.$sp01};

    position: relative;
    padding: var(--outer-spacing);
    color: var(--text-color);
    background: var(--ring-color);

    &,
    > div {
      transition: opacity 0.3s ease-in-out, padding 0.3s ease-in-out;
      transform: translate3d(0, 0, 0);
    }

    > div {
      position: relative;
      z-index: 1;
      border-radius: inherit;
      padding: calc(#{spacing.$spacing-xs} - var(--outer-spacing)) calc(#{spacing.$spacing-s} - var(--outer-spacing));
      background: var(--inner-background);
    }

    &::before {
      @include pseudo-layer;

      background: color.$vibrant-gradient;
      border-radius: inherit;
    }

    &:not(:disabled) {
      &:focus-visible,
      &:hover {
        --outer-spacing: #{size.$sp04 * .75};

        color: var(--text-color);

        &::before {
          opacity: 1;
        }
      }

      &:active {
        --outer-spacing: #{spacing.$spacing-xxs - 7};

        color: var(--text-color);

        &::before {
          opacity: 1;
        }
      }
    }

    &.secondaryOnWhite {
      --text-color: #{color.$valid-black};
      --inner-background: #{color.$conscoius-white};
      --ring-color: #{color.$valid-black};
    }

    &.secondaryOnBlack {
      --text-color: #{color.$conscoius-white};
      --inner-background: #{color.$valid-black};
      --ring-color: #{color.$conscoius-white};
    }
  }

  &.iconOnly {
    padding: 0;

    > div > span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.vibrant {
    transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out,
    background 0.3s ease-in-out;
    padding: spacing.$spacing-xs spacing.$spacing-s;
    color: color.$conscoius-white;
    background: color.$vibrant-gradient;

    &::before,
    &::after {
      @include pseudo-layer;
    }

    &::before {
      z-index: 0;
    }

    &::after {
      z-index: 1;
    }

    &:not(:disabled) {
      &:focus-visible,
      &:hover {
        &::before {
          opacity: 1;
        }
      }

      &:active {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &.fullWidth {
    width: 100%;
  }
}
