@use "sass:color";

$valid-black: #000;
$conscoius-white: #FFF;
$valid-black-gradient: #0D0D0D;
$conscoius-white-gradient: #F2F2F2;
$vibrant100-digital: #E71341;
$vibrant100: #FF0037;
$vibrant200: #F60058;
$vibrant300: #DA0074;
$vibrant400: #C40A90;
$vibrant500: #AE25AA;
$vibrant600: #9735C5;
$vibrant700: #7B3EE4;
$vibrant800: #5550FF;
$conscoius-white-75: color.change($conscoius-white, $alpha: 0.75);
$valid-black-75: color.change($valid-black, $alpha: 0.75);
$valid-gradient: linear-gradient(141.69deg, $valid-black 0%, $valid-black-gradient 100%);
$vibrant-gradient: linear-gradient(141.69deg, $vibrant100-digital 0%, $vibrant800 100%);
$conscious-gradient: linear-gradient(135deg, $conscoius-white 0%, $conscoius-white-gradient 100%);
$gradient300: linear-gradient(135deg, #CA2075 0%, #B5288E 100%);
$gradient400: linear-gradient(135deg, #B5288E 100%, #9F31A7 100%);






