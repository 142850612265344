body {
  overscroll-behavior: none;
  min-height: 100vh !important;
}

svg {
  fill: currentColor;
}

$font-size-list: "sm" "md" "lg" "xl";
@each $value in $font-size-list {
  .font-size-#{$value} {
    font-size: rfs-value($value + px) !important;
  }
}

@media only screen and (min-width: 390px) {
  .general-font-lg {
    font-size: 27px;
  }
}

@media only screen and (min-width: 600px) {
  .general-font-lg {
    font-size: 32px;
  }
}

@media only screen and (min-width: 821px) {
  .general-font-lg {
    font-size: 37px;
  }
}
