.mainView {
  grid-template-columns: 1fr;
}

@media only screen and (min-width: 600px) {
  .mainView {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 821px) {
  .mainView {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 1401px) {
  .headerAlign {
    max-width: 1330px;
  }
}
