// Internal general base-unit -- change with very much caution.
$base: 4px;

// Size Variables
$sp01: $base * .25;
$sp02: $base * .5;
$sp04: $base;
$sp08: $base * 2;
$sp12: $base * 3;
$sp16: $base * 4;
$sp20: $base * 5;
$sp24: $base * 6;
$sp28: $base * 7;
$sp32: $base * 8;
$sp36: $base * 9;
$sp40: $base * 10;
$sp44: $base * 11;
$sp48: $base * 12;
$sp52: $base * 13;
$sp56: $base * 14;
$sp60: $base * 15;
